import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { throttle } from 'lodash';

import '../styles/Nav.css';

import { ReactComponent as NavBtnIcon} from '../assets/images/nav-menu.svg';

function Nav(){
    const [ navClasses, setNavClasses ] = useState("nav nav-white");
    const [ displaySideMenu, setDisplaySideMenu ] = useState(false);
    const location = useLocation();

    const links = [
        {to: "/", text: "INTRODUCTION"},
        {to: "/i-earn-gold", text: "I-EARN GOLD"},
        {to: "/blog", text: "BLOG"},
        {to: "/about", text: "ABOUT US"}
    ]

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return() => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    const linkClick = (event) => {
        const eventTarget = event.currentTarget.getAttribute("href");
        
        
        if(eventTarget !== location.pathname){
            setDisplaySideMenu(false);
        }
    };
    
    function toggleSideMenu() {
        setDisplaySideMenu(!displaySideMenu);
    }

    const handleResize = throttle(() => {
        if( window.innerWidth >= 768 ) {
            setDisplaySideMenu(false);
        }
    }, 200);

    return(
        <>
        <nav className={navClasses}>
            <div className="nav-logo">
            <Link to="/" onClick={linkClick}>
            <img src="./ryon-asset-positive.png" alt="RYON ASSET" />
            </Link>
            </div>
            
            <ul>
                {links.map((link, index) => (
                    <Link to={link.to} key={index} onClick={linkClick}>
                        <li className="nav-button"><span>{link.text}</span><div className="nav-button-bar" /></li>
                    </Link>
                ))}
            </ul>
            <div className='nav-menu-button' onClick={toggleSideMenu}><NavBtnIcon /></div>
        </nav>
        <div className={'side-menu-backdrop' + (displaySideMenu ? ' side-menu-backdrop-show' : '')} onClick={toggleSideMenu} />
        <div className={'side-menu' + (displaySideMenu ? ' side-menu-show' : '')}>
            { links.map((link, index) => (
                <Link to={link.to} key={index} onClick={linkClick}>
                    <span>{link.text}</span>
                </Link>
            )) }
        </div>
        </>
    );
}

export default Nav;