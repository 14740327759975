
function Section(props){
    return(
        <section className="sect-container">
            <h3>{ props.title }</h3>
            <div className={"sect-desc " + (props.detailClass ? props.detailClass : '')}>
            { props.children }
            </div>
        </section>
    );
}

export default Section;