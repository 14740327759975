import React, { useState } from 'react';

import Section from "../components/Section";
import Footer from "../components/Footer";
import Member from '../components/Member';
import { memberList } from '../components/MemberList'; 

import '../styles/Employee.css'

import LogoEnvisager from '../assets/images/envisager_logo.png';
import LogoIsta from '../assets/images/ista_logo.png';
import ContactUs from "../components/ContactUs";

function About(){
    const [selectedMember, setSelectedMember] = useState(null);
    const [isMemberSelected, setIsMemberSelected] = useState(false);

    const handleMemberClick = (memberData) => {
        if(!isMemberSelected || memberData.name !== selectedMember.name) {
            setSelectedMember(memberData);
            setIsMemberSelected(true);    
        } else {
            setIsMemberSelected(false);
        }
        
    }

    const renderHistory = (member) => {
        return(
        member.history.map((item, index) => (
            <li className='emp-details-aff-container' key={index}>
                <span className='emp-details-aff'>{ item.aff }</span>
                <div className='emp-details-aff-pt'>
                    <span className='emp-details-aff-pos'>{ item.pos }</span>
                </div>
            </li>
        ))
        );
    }

    const renderMemberList = (group) => {
        console.log(`${selectedMember}`);
        return group.map((member, index) => (
            <Member key={index} member={member} isDetailsVisible={isMemberSelected && selectedMember.name === member.name} onClick={handleMemberClick} />
        ))
    }

    return(
        <div className='main-wrapper'>
        <main style={{paddingTop:'100px'}}>            
            <Section title="Our Team" detailClass="emp-sect-container">
            <div className="sect-side-space">
                <div className="emp-sect-grid">
                    <div className="emp-teammate">
                        <h4>Teammate</h4>
                        <ul className="emp-container">
                            {renderMemberList(memberList.team)}
                        </ul>
                    </div>
                    <div className="emp-advisor">
                        <h4>Advisor</h4>
                        <ul className="emp-container">
                            {renderMemberList(memberList.advisor)}
                        </ul>
                    </div>
                    <div className="emp-branch">
                        <h4>Branch</h4>
                        <ul className="emp-container">
                            {renderMemberList(memberList.branch)}
                        </ul>
                    </div>
                    <div className="emp-details">
                        <div className="emp-details-overlay-wrapper">
                            <div className=
                            {
                                isMemberSelected ?
                                "emp-details-overlay emp-details-overlay-display"
                                :
                                "emp-details-overlay"
                            }>
                                <p>
                                <span className='emp-details-overlay-header'>RYON TEAM</span>
                                <br/><br/>
                                Will redefine<br/>
                                The way to invest<br/>
                                In gold through<br/>
                                Innovative<br/>
                                Blockchain fintech solutions.
                                </p>
                            </div>
                        </div>
                        <div className='emp-details-container'>
                            <h3>{ selectedMember && selectedMember.fullname }</h3>
                            <h4>{ selectedMember && selectedMember.pos }</h4>
                            <hr />
                            <div>
                                <p className='emp-details-desc'>
                                    { selectedMember && selectedMember.desc}
                                </p>
                                <ul>
                                { selectedMember && renderHistory(selectedMember) }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </Section>
            <Section title="Partner">
            <div className='sect-side-space'>
            <div className='partner'>
                <div className='partner-image-helper'></div>
                <a href="https://www.envisager.kr/" target='_blank' rel="noreferrer">
                    <img src={LogoEnvisager} className="partner-image" alt="ENVISAGER" />
                </a>
                <a href="https://www.ista.online/" target='_blank' rel="noreferrer">
                    <img id="partner-ista-logo" src={LogoIsta} className="partner-image" alt="ISTA" />
                </a>
            </div>
            </div>
        </Section>
        <Section title="Contact Us">
        <div className="sect-side-space">
        <h4>Connect with us – we're just a message away.</h4>
        <hr/>
        <ContactUs />
        </div>
        </Section>
        </main>

        <Footer />
        </div>
    );
}

export default About;