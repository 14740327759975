import MemberImgNA from '../assets/images/img-member-na.png';

import MemberImgAugustin from '../assets/images/img-member-augustin.png';
import MemberImgSimon from '../assets/images/img-member-simon.png';
import MemberImgEugene from '../assets/images/img-member-eugene.png';
import MemberImgMinsung from '../assets/images/img-member-minsung.png';
import MemberImgYongchan from '../assets/images/img-member-yongchan.png';
import MemberImgJackie from '../assets/images/img-member-jackie.png';
import MemberImgHyojun from '../assets/images/img-member-hyojun.png';
import MemberImgRichard from '../assets/images/img-member-richard.png';
import MemberImgSutaek from '../assets/images/img-member-sutaek.png';
import MemberImgSangwon from '../assets/images/img-member-sangwon.png';
import MemberImgHugh from '../assets/images/img-member-hugh.png';


import createMemberInfo from './MemberInfo';


const eugene = createMemberInfo(
  "Eugene", "Eugene Yoon", "Co-Founder", MemberImgEugene, "", [
      { aff: "ITCEN Crypto Bank TFT", pos: "" },
      { aff: "ProBit Exchange", pos: "CFO" },
      { aff: "KPMG Samjong", pos: "CPA" },
      { aff: "Chung-Ang University", pos: "BBA" },
      { aff: "KAIST", pos: "Digital Asset Specialist" },
  ]
);


const jackie = createMemberInfo(
  "Jackie", "Jacquelin Y.Chin", "Co-Founder", MemberImgJackie, "", [
    { aff: "Westminster Funds Asset", pos: "Manager" },
    { aff: "Morrison Mahoney & Miller Law Firm", pos: "N.Y." },
    { aff: "Arthur Andersen", pos: "Financial Consultant" },
    { aff: "Tulane Law School", pos: "J.D. N.Y. Bar" },
    { aff: "University of Chicago", pos: "B.A." },
  ]
);


const simon = createMemberInfo(
  "Simon", "Simon Seo", "CSO", MemberImgSimon, "", [
      { aff: "ITCEN", pos: "CSO" },
      { aff: "Arthur D. Little Korea", pos: "" },
      { aff: "World Bank", pos: "Junior Researcher" },
      { aff: "Seoul National University", pos: "M.A. in Economics" },
      { aff: "Georgetown University", pos: "M.A. in Economics" },
  ]
);

const minsung = createMemberInfo(
  "Minsung", "Minsung Kim", "CIO", MemberImgMinsung, "", [
      { aff: "Dongbu Life", pos: "Asset Manager" },
      { aff: "SOL PE", pos: "Fund Manager" },
      { aff: "Chung-Ang University", pos: "BBA" },
  ]
);


const hugh = createMemberInfo(
  "Hugh", "Hugh Yoon", "Front-End Engineer", MemberImgNA, "", [
      { aff: "Undergraduate Student", pos: "" },
  ]
);

const augustin = createMemberInfo(
  "Augustin", "Augustin Jiang", "Global Partnership", MemberImgAugustin, "", [
      { aff: "ISTA", pos: "Secretary General" },
      { aff: "World Digital Art-Fair", pos: "Chairman" },
      { aff: "China Culture and Art Co., Ltd.", pos: "Vice Chairman" },
      { aff: "Kyungpook National University", pos: "M.A." },
  ]
);

const yongchan = createMemberInfo(
  "Yongchan", "Yongchan Keum", "Technical Advisor", MemberImgYongchan, "", [
      { aff: "ENVISAGER", pos: "Founder" },
      { aff: "GNC Solution", pos: "CEO" },
      { aff: "Seoul National University", pos: "Dept. of Nuclear Engineering" },
  ]
);

const hyojun = createMemberInfo(
  "Hyojun", "Hyo-jun Woo", "COO", MemberImgHyojun, "", [
      { aff: "Frasen Inc.", pos: "CEO" },
      { aff: "KAIST UTRC", pos: "Researcher" },
      { aff: "Hanyang University", pos: "" },
      { aff: "KAIST", pos: "M.S. in Mechanical Engineering" },
  ]
);

const richard = createMemberInfo(
  "Richard", "Richard Kim", "CTO", MemberImgRichard, "", [
      { aff: "PERI Finance", pos: "Co-Founder, CEO" },
      { aff: "Fidelity Information System", pos: "Head Of Operations" },
      { aff: "FBP limited", pos: "CEO/CTO" },
      { aff: "SNBANK", pos: "CTO" },
      { aff: "Korea Finance IT Service", pos: "Software Engineer" },
  ]
);

const sangwon = createMemberInfo(
  "Sangwon", "Sangwon Lee", "Gold Market", MemberImgSangwon, "", [
      { aff: "SC Bank", pos: "IFD, Dealing Room, Foreign Exchange Dealer" },
      { aff: "Meritz Securities", pos: "FICC, General Manager" },
      { aff: "Agricultural Bank of China Ltd", pos: "Treasury Department, General Manager" },
      { aff: "Busan Bank, Seoul Branch", pos: "IFD, Dealing Room, Deputy General Manager" },
  ]
);

const sutaek = createMemberInfo(
  "Soo-Taek", "Soo-Taek Lim", "Ryon Japan", MemberImgSutaek, "", [
      { aff: "Waseda University", pos: "MPA, GSPM" },
      { aff: "Matsushita Seikeijyuku", pos: "Researcher" },
      { aff: "Office of the President(ROK)", pos: "Administrative Officer" },
      { aff: "Tokyo Management College", pos: "Visiting Professor" },
  ]
);


export const memberList = {
  team: [
    eugene,
    jackie,
    richard,
    hyojun,
    simon,
    minsung,
    hugh,
  ],
  advisor: [
    augustin,
    yongchan,
    sangwon,
  ],
  branch: [
    sutaek,
  ]
}