import React, { useState } from 'react';

function Member({ member = {}, isDetailsVisible, onClick }) {
    const { name, fullname, pos, img, desc, history = [] } = member;
    const [showFullNames, setShowFullNames] = useState(false);

    const renderHistory = ({ history }) => {
        return(
        history.map((item, index) => (
            <li className='emp-details-aff-container' key={index}>
                <span className='emp-details-aff'>{ item.aff }</span>
                <div className='emp-details-aff-pt'>
                    <span className='emp-details-aff-pos'>{ item.pos }</span>
                </div>
            </li>
        ))
        );
    }

    React.useEffect(() => {
        const handleResize = () => {
            setShowFullNames(window.innerWidth < 1024);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return(
        <li className="emp-wrapper" onClick={() => { onClick(member); }}>
            <img className="emp-picture" src={ img } alt="Member"/>
            <div className="emp-text">
                <p className='emp-name'>{ showFullNames ? fullname : name }</p>
                <p className='emp-position'>{ pos }</p>
            </div>
            <div className={`emp-wrapper-details ${isDetailsVisible ? 'emp-wrapper-details-show' : ''}`}>
                <hr/>
                <p>{ desc }</p>
                <ul>
                    { renderHistory({ history }) }
                </ul>
            </div>
        </li>
    );
}

export default Member;